
<template>
    <div data-app style="margin-top: -8px">
        <div>
            <UserInfo :begin="begin" @changeStatus="changeStatus"></UserInfo>
        </div>
        <div>
            <div class="e-content"  v-for="(docs, title) in homeData" :key="title">
                <div class="e-title">
                    {{title}}
                </div>
                <div class="e-doc-item-list">
                    <div class="e-doc-item"  v-for="item in docs" :key="item._id">
                        <div class="doc-author">
                            {{ item.author }}
                        </div>
                        <div class="doc-title">
                            {{ item.title }}
                        </div>
                        <div class="overlay-view">
                            <div class="e-toolbar">
                                <v-menu
                                    bottom
                                    left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                    </template>
                                    <v-list>
                                    <v-list-item @click="goDoc(item.doc_id||item._id, 'speed')">
                                        <v-list-item-title>Speed</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="goDoc(item.doc_id||item._id, 'view')">
                                        <v-list-item-title>View</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>Hide From Recent</v-list-item-title>
                                    </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                            <button class="btn btn-primary" @click="goDoc(item.doc_id||item._id, 'speed')">
                                Speed
                            </button>
                            <button class="btn btn-outline-secondary" @click="goDoc(item.doc_id||item._id, 'view')">
                                View
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<style lang="scss">

@import "@/assets/sass/pages/begin/begin.scss";

.Zindex{
    z-index: 100000;
}
.noZindex{
    z-index: 9;
}
</style>

<script>
import UserInfo from "@/view/content/user/Userinfo.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import {
  GET_HOME_DATA,
} from "@/core/services/store/data.module.js";

import { mapGetters } from "vuex";

export default {
  name: "inicio",
  components: {
    UserInfo,
  },
  data() {
      return {
          previous:"",
          pageStatus: "home",
          category: "",
          items: [
            { title: 'Speed' },
            { title: 'View' },
            { title: 'Hide from Recent' },
          ],
      }
  },
  created() {
    this.getData();
  },
  methods: {
    changeStatus(status) {
        if (this.pageStatus == status) {
            return;
        }
        this.pageStatus = status;
        this.getData();
    },
    getData() {
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        this.$store
          .dispatch(GET_HOME_DATA, { status: this.pageStatus, category: this.category})
          .then(() => {
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          })
          .catch(() => {
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          });
    },
    goDoc(id, status) {
        console.log(id, status);
        this.$router.push(`/doc/${id}/${status}`);
    }
  },
   computed: {
        ...mapGetters([
            "homeData"
        ]),
   }
}

</script>